import { Routes, Route } from 'react-router-dom';
import { Home, Rules } from '../app/index';
import PageNotFound from '../app/PageNotFound/PageNotFound';
import { RoutePattern } from './RoutePattern';

export default function AppRoutes() {
  return (
    <Routes>
      <Route path={RoutePattern.Home} element={<Home />} />
      <Route path={RoutePattern.Rules} element={<Rules />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
