import AppRoutes from './routes/AppRoutes';

import './main.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="app min-h-screen">
      <Router>
        <Routes>
          <Route path="*" element={<AppRoutes />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
